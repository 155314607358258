import { observable, action } from 'mobx';
import { QuickClaimQuote, EducationStatus } from '../domain/quick-claim-quote';
import api from '../api';

const invalidSumAssured = (sumAssured?: number) =>
  !sumAssured || sumAssured < 50000 || sumAssured > 200000 || isNaN(sumAssured);
const invalidIncome = (income?: number) =>
  typeof income === 'undefined' || income < 0 || isNaN(income);
const invalidAge = (age?: number) => !age || isNaN(age) || age < 17 || age > 59;
const invalidSouthAfricanCitizen = (saCitizen?: string) => saCitizen !== 'true';
export class QuoteStore {
  @observable isLoading = false;
  @observable quoteInput: Partial<QuickClaimQuote> = {
    sum_assured: 200000,
    sum_assured_additional_member: 200000,
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable quoteResults: any = [];
  @observable selectedQuoteIndex?: number = -1;

  fieldsToFill: string[] = [];

  constructor() {
    this.validate()
    this.validationResult = {}
  }

  validate = () => {
    const request = this.quoteInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.sum_assured = invalidSumAssured(request.sum_assured)
      ? 'Sum assured should be between R 50 000 and R 200 000.'
      : '';

    this.validationResult.income = invalidIncome(request.income)
      ? 'Income should be greater or equal than R 0.'
      : '';

    this.validationResult.gender = !request.gender ? 'Select the gender.' : '';

    this.validationResult.age = invalidAge(request.age)
      ? 'Age can range between 17 and 59.'
      : '';

    this.validationResult.smoker = !request.smoker
      ? 'Select smoking status.'
      : '';

    this.validationResult.south_african_citizen = invalidSouthAfricanCitizen(
      request.south_african_citizen
    )
      ? 'This product is only eligible to RSA citizens residing in the republic and in possession of a valid RSA ID number.'
      : '';

    this.validationResult.education_status = !request.education_status
      ? 'Select your education status.'
      : '';

    if (request.additional_member_included) {
      this.validationResult.sum_assured_additional_member = invalidSumAssured(
        request.sum_assured_additional_member
      )
        ? 'Sum assured should be between R 50 000 and R 200 000.'
        : '';

      this.validationResult.income_additional_member = invalidIncome(
        request.income_additional_member
      )
        ? 'Income should be greater or equal than R 0.'
        : '';

      this.validationResult.gender_additional_member = !request.gender_additional_member
        ? 'Select the gender.'
        : '';

      this.validationResult.age_additional_member = invalidAge(
        request.age_additional_member
      )
        ? 'Age can range between 17 and 59.'
        : '';

      this.validationResult.smoker_additional_member = !request.smoker_additional_member
        ? 'Select smoking status.'
        : '';

      this.validationResult.south_african_citizen_additional_member = invalidSouthAfricanCitizen(
        request.south_african_citizen_additional_member
      )
        ? 'This product is only eligible to RSA citizens residing in the republic and in possession of a valid RSA ID number.'
        : '';

      this.validationResult.education_status_additional_member = !request.education_status_additional_member
        ? 'Select your education status.'
        : '';
    }

    this.fieldsToFill = Object.keys(this.validationResult)
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getSelectedQuotePackage = () => {
    return this.quoteResults[this.selectedQuoteIndex];
  };

  getApiQuoteInput = () => {
    const {
      sum_assured,
      income,
      smoker,
      south_african_citizen,
      gender,
      education_status,
      age,

      additional_member_included,

      gender_additional_member,
      education_status_additional_member,
      age_additional_member,
      sum_assured_additional_member,
      income_additional_member,
      smoker_additional_member,
      south_african_citizen_additional_member
    } = this.quoteInput;

    return {
      gender,
      education_status,
      age,
      sum_assured: sum_assured * 100,
      income: income * 100,
      smoker: smoker === 'true',
      south_african_citizen: south_african_citizen === 'true',
      broker_branch: 'Shackleton',
      lead_provider: 'Shackleton Life Digital',
      unlisted_lead_provider: false,
      ...(additional_member_included
        ? {
            gender_additional_member,
            education_status_additional_member,
            age_additional_member,
            additional_member_included,
            income_additional_member: income_additional_member * 100,
            sum_assured_additional_member: sum_assured_additional_member * 100,
            smoker_additional_member: smoker_additional_member === 'true',
            south_african_citizen_additional_member: !!south_african_citizen_additional_member
          }
        : {
            additional_member_included: false
          })
    };
  };

  getQuote = async () => {
    this.validate();
    if (this.hasValidationError()) {
      return;
    }

    const quoteInCents = this.getApiQuoteInput();
    this.isLoading = true;
    try {
      const result = await api.getQuote('shackleton_quickclaim', quoteInCents);
      this.quoteResults = result;
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  setSelectedQuoteIndex = (index: number) => {
    this.selectedQuoteIndex = index;
  };

  @action
  addAdditionalMember = () => {
    this.quoteInput.additional_member_included = true;
    this.validate();
    this.validationResult = {}
  }
  @action
  removeAdditionalMember = () => {
    this.quoteInput.additional_member_included = false;
    this.validate();
    this.validationResult = {}
  }

  get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }

  get selectedQuote () {
    return this.quoteResults[this.selectedQuoteIndex || 0];
  }
}

export const quoteStore = new QuoteStore();
(window as any).quoteStore = quoteStore;
