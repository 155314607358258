import { utc as moment, Moment } from 'moment'

export const getNextBillingDate = (from: Moment, billingDay: number) => {
    const billingDateInFromMonth = getBillingDateInMonth(from.clone(), billingDay);
    if (from.isBefore(billingDateInFromMonth, 'day')) {
        return billingDateInFromMonth;
    } else {
        return getBillingDateInMonth(from.clone().add(1, 'month'), billingDay);
    }
};

export const getBillingDateInMonth = (from: Moment, billingDay: number) => {
    const lastDayInFromMonth = from.clone().endOf('month').get('date');
    if (billingDay >= lastDayInFromMonth) {
        return from.clone().endOf('month').startOf('day');
    } else {
        return from.clone().date(billingDay).startOf('day');
    }
};

export const calculateMonthlyProRata = (monthlyPremium: number, billingDay: number, from: Moment = moment()) => {
    from.startOf('day'); // the from day must be included in calculation

    const billingDateAfterIssue = getNextBillingDate(from, billingDay);

    // use the fraction of the period between one billing period and the next
    // to calculate the fraction of the premium to use as pro-rata
    const nextBillingDate = getNextBillingDate(moment(), billingDay);
    const billingDateBeforeIssue = billingDateAfterIssue.clone().subtract(1, 'month');
    const periodBetweenIssueBillingDays = billingDateAfterIssue.diff(billingDateBeforeIssue, 'days');
    const daysFromIssueToBilling = billingDateAfterIssue.diff(from, 'days');

    const proRata = Math.round(monthlyPremium * (daysFromIssueToBilling / periodBetweenIssueBillingDays));

    const months = billingDateAfterIssue.diff(nextBillingDate, 'months');
    const accumulatedPremium = months * monthlyPremium;

    return proRata + accumulatedPremium;
};

export const calculateYearlyProRata = (yearlyPremium: number, from: Moment = moment()) => {
    const nextYear = moment(`${moment().add(1, 'year').year()}-01-01`)
    const daysTillNextYear = nextYear.diff(from, 'days')
    const annualPremium = yearlyPremium * 12;
    return annualPremium * (daysTillNextYear / 365)
}