import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { banks } from '../../helpers/data';
import { inject, observer } from 'mobx-react';
import { PaymentDetailsStore } from '../../stores/payment-details-store';
import { SelectInput } from '../inputs/select';
import { TextInput } from '../inputs/text';
import { PolicyholderStore } from '../../stores/policyholder-store';
import ordinal from 'ordinal'
import { QuoteStore } from '../../stores/quote-store';
import { zarString } from '../../helpers/format';

const billingDays = Array.from(Array(31).keys()).map((value, index) => ({
  value: (index + 1).toString(),
  label: ordinal(index + 1)
}))

interface Props {
  nextStep: () => void;
  previousStep: () => void;
  paymentDetailsStore?: PaymentDetailsStore;
  policyholderStore?: PolicyholderStore;
  quoteStore?: QuoteStore;
}

@inject('paymentDetailsStore', 'policyholderStore', 'quoteStore')
@observer
class PaymentDetails extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {}
    };
  }

  continue() {
    this.setState({ clicked: true });
    this.props.paymentDetailsStore.validate();

    if (!this.props.paymentDetailsStore.hasValidationError()) {
      this.props.nextStep();
    }
  }

  render() {
    if (!this.props.paymentDetailsStore) {
      return null;
    }

    const paymentMethodInput = this.props.paymentDetailsStore
      .paymentMethodInput;
    const validationResult = this.props.paymentDetailsStore.validationResult;

    return (
      <div>
        <h1>Payment details</h1>
        <br />
        <h5>Select how you will pay your monthly premiums</h5>
        <br />

        <Row>
          <Col sm="6">
            <FormGroup>
              <Label for="paymentMethod">Payment method</Label>
              <Input
                type="select"
                name="select"
                id="paymentMethod"
                value="debit_order"
                disabled
              >
                <option value="debit_order">Debit order</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p>
              <b>Bank details</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <SelectInput
              id="bank_name"
              label="Bank name*"
              value={paymentMethodInput.bank_details_bank_name}
              onChange={e =>
                (paymentMethodInput.bank_details_bank_name = e.target.value)
              }
              invalid={!!validationResult.bank_details_bank_name}
              validationMessage={validationResult.bank_details_bank_name}
              options={banks}
            />
          </Col>
          <Col sm="6">
            <TextInput
              id="branch_code"
              label="Branch code*"
              value={paymentMethodInput.bank_details_branch_code}
              onChange={e =>
                (paymentMethodInput.bank_details_branch_code = e.target.value)
              }
              invalid={!!validationResult.bank_details_branch_code}
              validationMessage={validationResult.bank_details_branch_code}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <TextInput
              id="account_holder"
              label="Account holder*"
              value={paymentMethodInput.bank_details_account_holder}
              onChange={e =>
                (paymentMethodInput.bank_details_account_holder =
                  e.target.value)
              }
              invalid={!!validationResult.bank_details_account_holder}
              validationMessage={validationResult.bank_details_account_holder}
            />
          </Col>
          <Col sm="6">
            <TextInput
              id="account_number"
              label="Account number*"
              value={paymentMethodInput.bank_details_account_number}
              onChange={e =>
                (paymentMethodInput.bank_details_account_number =
                  e.target.value)
              }
              invalid={!!validationResult.bank_details_account_number}
              validationMessage={validationResult.bank_details_account_number}
            />
          </Col>
        </Row>

        {
          this.props.quoteStore &&
          this.props.quoteStore.getSelectedQuotePackage() &&
          this.props.quoteStore.getSelectedQuotePackage().billing_frequency === 'monthly' &&
          <Row>
            <Col sm="6">
              <SelectInput
                id="billing_day"
                label="Preferred billing day*"
                value={paymentMethodInput.billing_day}
                onChange={e =>
                  (paymentMethodInput.billing_day = e.target.value)
                }
                invalid={!!validationResult.billing_day}
                validationMessage={validationResult.billing_day}
                options={billingDays}
              />
            </Col>
          </Row>
        }

        {
          this.props.quoteStore &&
          this.props.quoteStore.getSelectedQuotePackage() &&
          <Alert color='info'>
            A pro rata amount of {' '}{
              zarString(
                this.props.paymentDetailsStore.getProRataAmount(
                  this.props.quoteStore.getSelectedQuotePackage().suggested_premium,
                  this.props.quoteStore.getSelectedQuotePackage().billing_frequency
                )
              )
            }{' '}will be due upon activation of the policy.
          </Alert>
        }

        <br />
        <br />
        <Button
          color="primary"
          className="px-5"
          onClick={this.continue.bind(this)}
        >
          Continue
        </Button>
        <Button color="link" onClick={this.props.previousStep}>
          Back
        </Button>
        <br />
        <br />
        <Button
          color="primary"
          className="px-5"
          onClick={() => this.props.policyholderStore.toggleCallMeModal()}
        >
          Call me
        </Button>
      </div>
    );
  }
}

export default PaymentDetails;
