import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const How: React.SFC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>How does it work?</h2>
        The <strong>Shackleton Life QuickClaim</strong> Policy for Immediate
        Expenses is underwritten by OMART (Old Mutual Alternative Risk Transfer
        Limited) a licensed life insurer and a company in the Old Mutual
        Group.
        <br />
        <br />

        The <strong>Shackleton Life QuickClaim</strong> Policy for immediate
        expenses pays out the Selected Sum Assured amount as a lump sum directly
        to the nominated beneficiary, usually the spouse or dependents, within
        two working days of receipt of the death certificate and all claim
        documentation supporting a valid claim. This amount can be used to pay
        for the immediate or final expenses associated with the fact that the
        deceased’s bank account has been frozen.
        <br />
        <br />
        The policy is a whole of life policy meaning that it can be kept for
        your whole of life as long as you continue to pay the premiums, there is
        no term associated with the policy.
        <br />
        <br />
        You can choose cover for any amount between R50 000 – R200 000 cover
        <br />
        <br />
        Minimum entry age – 18 years old Maximum entry age – 60 years old
        <br />
        <br />
      </p>
    </div>
  );
};
export const Why: React.SFC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>
          Why the <strong>Shackleton Life QuickClaim</strong> Product?
        </h2>
        Did you know that when you pass away all your bank accounts are frozen
        as soon as possible after your death until an Executor is appointed, and
        your dependants cannot access your bank accounts for their day-to-day
        living expenses.
        <br />
        <br />
        For a family this is a difficult time: If you are the breadwinner this
        could be disastrous for your dependents, as they would need access to
        the money in your accounts to pay for immediate expenses such as
        household bills, day-to-day expenses, food, bonds, vehicle instalments,
        school fees, etc.
        <br />
        <br />
        Without access to money from your bank accounts where will they get
        funds for day-to-day expenses while they wait for the Estate to get
        finalised?
        <br />
        <br />
        The QuickClaim benefit pays your nominated beneficiary between R50,000 -
        R200,000 to be used for your dependents’ immediate needs and expenses
        within two working days of receipt of the death certificate and all
        required claims documentation.
        <br />
        <br />
        A family may have Life Cover, but this typically takes a while to pay
        out and is usually put in place for a specific need, and not for the
        immediate day-to-day living expenses required by others after your
        death.
        <br />
        <br />
        This is why it is importaint to consider a policy such as QuickClaim to
        provide for immediate living expenses.
        <br />
        <br />
        The QuickClaim policy will pay the beneficiary named in the policy
        within two working days of receipt of all claim documents supporting a
        valid claim, to enable them to pay for these immediate expenses by
        giving them access to the money required during this difficult time.
      </p>
    </div>
  );
};
export const Benefits: React.SFC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Benefits</h2>
        The <strong>Shackleton Life QuickClaim</strong> Policy for immediate
        expenses has the following benefits:
        <ul className="infoList">
          <li>
            No medicals or blood tests required - With the{' '}
            <strong>Shackleton Life QuickClaim</strong> Policy, there are no
            medicals or blood tests required and as long as you are over 18 and
            under 60 years of age at entry, everyone qualifies. There is however
            a 3-month waiting period for Natural Death and General Exclusions
            applicable (see payment of benefits below).
          </li>
          <li>
            Whole of Life Policy – the policy is a whole of life policy, which
            means that there is no term to the cover and as long as you are
            paying your premiums, you may keep the policy for your whole life.
          </li>
          <li>
            Nominate a beneficiary – It is important that you nominate a
            beneficiary, being the person who will be responsible for these
            expenses in the event of your death. The sum you are assured for
            will be paid directly to the beneficiary.
          </li>
          <li>
            Joint Policy – Two lives may be insured under one policy. Each Life
            Assured shall be entitled to the full Sum Assured provided that the
            premiums are paid in accordance with the policy and subject to the
            terms of the policy and payment of benefits. In the event of the
            death of one Life Assured, the policy will continue in the name of
            the surviving Life Assured with a recalculated premium on the
            remaining life assured’s age and risk factors.
          </li>
          <li>Up to R200 000 cover</li>
          <li>
            Ease of application – we handle all the paperwork ensuring you ease
            of application.
          </li>
          <li>
            May increase your cover at any stage – as long as it remains below
            the maximum and you are under 60 years of age, you may increase the
            cover at any stage. Exclusions and waiting periods will apply to the
            increased amount
          </li>
          <li>
            Payout within two working days of receipt of death certificate and
            all claim documentation supporting a valid claim.
          </li>
          <li>Payment of Benefits</li>
          <li>
            In terms of any Accidental Death Claim the full Sum Assured will be
            paid within two working days of receipt of all claim{' '}
          </li>
          documentation on a valid claim, provided the policy is in force and
          premiums are up to date.
          <li>
            In terms of any claim for death due to Natural Causes the following
            levels of benefits will apply from the Commencement of Insurance,
            and any reinstatement and any increase:-
          </li>
          <li>First 3 months: - 0% of the Sum Assured (waiting period)</li>
          <li>3 months to 6 months: - 50% of the Sum Assured.</li>
          <li>6 months to 12 months: - 80% of the Sum Assured.</li>
          <li>12 months onwards: - 100% of the Sum Assured.</li>
        </ul>
      </p>
    </div>
  );
};

interface Props {
  onClick: () => void;
  text: string;
}

export const ReadMoreButton: React.SFC<Props> = props => {
  return (
    <Button
      style={{
        margin: '0 8px',
        color: 'white',
        borderColor: 'white',
        background: 'rgba(0,0,0,0)'
      }}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );
};

class Landing extends Component {
  state = {
    howOpen: false,
    whyOpen: false,
    benefitsOpen: false,
  };

  render() {
    return (
      <div className="fullscreen">
        <nav>
          <a href="/">
            <img alt='logo' src="logo.png" height="40px" />
          </a>
        </nav>

        <main className="landing-page-cover">
          <div id="section-landing-page-cover">
            <h1>We've got you covered!</h1>
            <h3>Get affordable cover today.</h3>
            <br />
            <a
              style={{ margin: 0 }}
              className="btn btn-lg btn-primary"
              href="/apply"
            >
              Get a quote!
            </a>
            <br />
            <br />
            <br />
            <br />
            <p
              style={{
                background: 'rgba(50,50,50,0.3)',
                padding: '16px',
                borderRadius: '4px'
              }}
            >
              <h2>
                <strong>Shackleton Life QuickClaim</strong> Policy for Immediate
                Needs
              </h2>
              <p>
                How can a spouse or dependents avoid the pain of trying to find
                money for immediate expenses due to a frozen bank account on the
                death of a breadwinner or husband or wife married in community
                of property?
              </p>

              <p>
                The <strong>Shackleton Life QuickClaim</strong> policy benefit
                will be paid within two working days of receiving all necessary
                documentation supporting a valid claim, and can be used to cover
                immediate living expenses after the death of a loved one.
              </p>
              <ReadMoreButton
                onClick={() => this.setState({ howOpen: true })}
                text={'How does it work'}
              />
              <ReadMoreButton
                onClick={() => this.setState({ whyOpen: true })}
                text={'Why the Shackleton Life Quickclaim product?'}
              />
              <ReadMoreButton
                onClick={() => this.setState({ benefitsOpen: true })}
                text={'Benefits'}
              />
            </p>
          </div>
          <br></br>

          {/* how modal */}
          <Modal isOpen={this.state.howOpen}>
            <ModalHeader
              toggle={() => this.setState({ howOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <How />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ howOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {/* why modal */}
          <Modal isOpen={this.state.whyOpen}>
            <ModalHeader
              toggle={() => this.setState({ whyOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <Why />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ whyOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
          {/* benefits modal */}
          <Modal isOpen={this.state.benefitsOpen}>
            <ModalHeader
              toggle={() => this.setState({ benefitsOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <Benefits />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ benefitsOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </main>
        <div id="footer"style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
          <img alt='logo-omart' src="OMART_footer_logo_horizontal.png" height="40px"/>
        </div>
      </div>
    );
  }
}

export default Landing;
