import { observable } from "mobx";
import { validateName, validateCellphone, validateIdNumber, validateEmail, validateDateOfBirth } from "../helpers/validation";

export class BeneficiariesStore {
  @observable inputBeneficiaries = [];

  @observable validationResult = [];

  validate = () => {
    const percentageError = this.validatePercentages();

    this.validationResult.forEach((vr, index) => {
      const {
        first_name,
        last_name,
        email,
        percentage,
        // cellphone,
        id_type,
        id_number,
        id_country,
        date_of_birth,
        relationship
      } = this.inputBeneficiaries[index]

      vr.first_name = !validateName(first_name)
        ? 'Enter a valid name.'
        : '';
      vr.last_name = !validateName(last_name)
        ? 'Enter a valid name.'
        : '';
      vr.relationship = !validateName(relationship)
        ? 'Select a valid relationship.'
        : '';
      vr.email = !validateEmail(email)
        ? 'Enter a valid email.'
        : '';
      // vr.cellphone = !validateCellphone(cellphone)
      //   ? 'Enter a valid cellphone number.'
      //   : '';

      vr.percentage = percentageError;

      vr.id_number =
        id_type === 'id'
          ? !validateIdNumber(id_number)
            ? 'Enter a valid SA identification number.'
            : ''
          : !id_number
            ? 'Enter a valid passport number.'
            : '';

      if (id_type === 'passport') {
        vr.date_of_birth = !validateDateOfBirth(
          date_of_birth,
          0,
          140
        )
          ? 'Enter a valid date of birth'
          : '';
        vr.id_country = !validateName(id_country) ? 'Select a country.' : '';
      }
    })
  }

  validatePercentages = () => {
    const total = this.inputBeneficiaries.reduce((prev, current) => {
      return prev + current.percentage
    }, 0)

    if (total !== 100) {
      return 'Percentages should add up to 100%'
    }

    return '';
  }

  getApiInput = () => {
    return this.inputBeneficiaries.map(beneficiary => {
      const { id_type, id_number, id_country, date_of_birth, ...mandatoryFields } = beneficiary;
      return {
        ...mandatoryFields,
        ...(id_type === 'passport' ? {date_of_birth} : {}),
        id: {
          type: id_type,
          number: id_number,
          country: id_country
        }
      }
    })
  }

  hasValidationError = () => {
    const validationError = this.validationResult.find(vr => {
      return Object.values(vr).find(value => value !== '' && value !== undefined)
    })

    if (validationError) return true

    return false
  }

  add = () => {
    this.inputBeneficiaries.push({
      id_type: 'id',
      id_country: 'ZA',
      percentage: 0
    })
    this.validationResult.push({})
  }

  remove = (index: number) => {
    this.inputBeneficiaries.splice(index, 1)
    this.validationResult.splice(index, 1)
  }
}

export const beneficiariesStore = new BeneficiariesStore();
(window as any).beneficiariesStore = beneficiariesStore;