import React, { Component } from 'react';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import { QuoteStore } from '../../stores/quote-store';
import { ApplicationStore } from '../../stores/application-store';
import { inject, observer } from 'mobx-react';
import { TextInput } from '../inputs/text';
import { DateInput } from '../inputs/date';
import { countries } from '../../helpers/countries';
import { SelectInput } from '../inputs/select';
import { yesNoOptions } from './create-quote';
import { PolicyholderStore } from '../../stores/policyholder-store';
import { allowedRelationships } from '../../helpers/data';

interface Props {
  nextStep: () => void;
  previousStep: () => void;
  quoteStore?: QuoteStore;
  applicationStore?: ApplicationStore;
  policyholderStore?: PolicyholderStore;
}

@inject('applicationStore', 'quoteStore', 'policyholderStore')
@observer
export class CreateApplication extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
    };
  }

  continue() {
    this.props.applicationStore.validate();
    if (!this.props.applicationStore.hasValidationError()) {
      this.props.nextStep();
    }
  }

  render() {
    if (!this.props.applicationStore) {
      return null;
    }

    const applicationInput = this.props.applicationStore.applicationInput;
    const validationResult = this.props.applicationStore.validationResult;

    return (
      <div>
        <h1>Application</h1>
        <br />
        <h4>Please complete the following details</h4>
        <br />

        <Row>
          <Col sm='6'>
            <SelectInput
              label='Preferred method of communication*'
              invalid={!!validationResult.preffered_method_to_receive_information}
              validationMessage={validationResult.preffered_method_to_receive_information}
              value={applicationInput.preffered_method_to_receive_information}
              onChange={e => {
                applicationInput.preffered_method_to_receive_information = e.target.value;
              }}
              id='preffered_method_to_receive_information'
              options={[
                {
                  value: 'post',
                  label: 'Post',
                },
                {
                  value: 'email',
                  label: 'Email',
                },
              ]}
            />
          </Col>
          <Col sm='6'>
            <SelectInput
              label='Is this a replacement policy?*'
              invalid={!!validationResult.replacement_policy}
              validationMessage={validationResult.replacement_policy}
              value={applicationInput.replacement_policy}
              onChange={e => {
                applicationInput.replacement_policy = e.target.value;
              }}
              id='replacement_policy'
              options={yesNoOptions}
            />
          </Col>
        </Row>
        {applicationInput.replacement_policy === 'true' && (
          <Row>
            <Col sm='12'>
              <p>
                If this policy is to replace any other policy with another assurer, we want you to ensure that you make
                an informed decision. You should contact your Financial Advisor who will assist you with proper advice
                and a detailed comparison as there may be limitations on the appropriateness of the replacement with
                this product. If you choose to continue you do so after consideration of this disclaimer and you do so
                at your own risk.
              </p>
            </Col>
          </Row>
        )}
        {this.props.quoteStore.getSelectedQuotePackage().module.additional_member_included && (
          <div>
            <br />
            <h4>Additional member details</h4>
            <br />
            <Row>
              <Col sm='6'>
                <TextInput
                  label='First name*'
                  invalid={!!validationResult.additional_member_first_name}
                  validationMessage={validationResult.additional_member_first_name}
                  value={applicationInput.additional_member_first_name}
                  onChange={e => {
                    applicationInput.additional_member_first_name = e.target.value;
                  }}
                  id='additional_member_first_name'
                />
              </Col>
              <Col sm='6'>
                <TextInput
                  label='Last name*'
                  invalid={!!validationResult.additional_member_last_name}
                  validationMessage={validationResult.additional_member_last_name}
                  value={applicationInput.additional_member_last_name}
                  onChange={e => {
                    applicationInput.additional_member_last_name = e.target.value;
                  }}
                  id='additional_member_last_name'
                />
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <TextInput
                  label='Email*'
                  invalid={!!validationResult.additional_member_email}
                  validationMessage={validationResult.additional_member_email}
                  value={applicationInput.additional_member_email}
                  onChange={e => {
                    applicationInput.additional_member_email = e.target.value;
                  }}
                  id='additional_member_email'
                />
              </Col>
              <Col sm='6'>
                <TextInput
                  label='Cellphone*'
                  invalid={!!validationResult.additional_member_cellphone}
                  validationMessage={validationResult.additional_member_cellphone}
                  value={applicationInput.additional_member_cellphone}
                  onChange={e => {
                    applicationInput.additional_member_cellphone = e.target.value;
                  }}
                  id='additional_member_cellphone'
                />
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <SelectInput
                  label='Relationship*'
                  invalid={!!validationResult.additional_member_relationship}
                  validationMessage={validationResult.additional_member_relationship}
                  value={applicationInput.additional_member_relationship}
                  onChange={e => {
                    applicationInput.additional_member_relationship = e.target.value;
                  }}
                  id='additional_member_relationship'
                  options={allowedRelationships}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <b>Type of identification</b>
                </p>
                <FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type='radio'
                        name='radioID'
                        onChange={() => {}}
                        checked={applicationInput.additional_member_id_type !== 'passport'}
                        onClick={() => {
                          applicationInput.additional_member_id_type = 'id';
                          applicationInput.additional_member_id_country = 'ZA';
                          this.props.applicationStore.validationResult = {};
                        }}
                      />{' '}
                      South African ID number
                    </Label>
                  </FormGroup>
                  {/* <FormGroup check inline>
                  <Label check>
                    <Input
                      type='radio'
                      name='radioPassport'
                      onChange={() => { }}
                      checked={applicationInput.additional_member_id_type === 'passport'}
                      onClick={() => {
                        applicationInput.additional_member_id_type = 'passport';
                        this.props.applicationStore.validationResult = {}
                      }}
                    />{' '}
                    Passport number
              </Label>
                </FormGroup> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <TextInput
                  label={applicationInput.additional_member_id_type === 'id' ? 'ID number*' : 'Passport number*'}
                  invalid={!!validationResult.additional_member_id_number}
                  validationMessage={validationResult.additional_member_id_number}
                  value={applicationInput.additional_member_id_number}
                  onChange={e => {
                    applicationInput.additional_member_id_number = e.target.value;
                  }}
                  id='additional_member_id_number'
                />
              </Col>
              {applicationInput.additional_member_id_type === 'passport' && (
                <>
                  <Col sm='6'>
                    <DateInput
                      label='Date of birth*'
                      invalid={!!validationResult.additional_member_date_of_birth}
                      validationMessage={validationResult.additional_member_date_of_birth}
                      value={applicationInput.additional_member_date_of_birth}
                      onChange={e => {
                        applicationInput.additional_member_date_of_birth = e.target.value;
                      }}
                      id='date_of_birth'
                    />
                  </Col>
                  <Col sm='6'>
                    <SelectInput
                      label='Country*'
                      invalid={!!validationResult.additional_member_id_country}
                      validationMessage={validationResult.additional_member_id_country}
                      value={applicationInput.additional_member_id_country}
                      onChange={e => {
                        applicationInput.additional_member_id_country = e.target.value;
                      }}
                      id='additional_member_id_country'
                      options={countries}
                    />
                  </Col>
                </>
              )}
            </Row>
          </div>
        )}

        <Button color='primary' className='px-5' onClick={this.continue.bind(this)}>
          Continue
        </Button>
        <Button color='link' onClick={this.props.previousStep}>
          Back
        </Button>
        <br />
        <br />
        <Button color='primary' className='px-5' onClick={() => this.props.policyholderStore.toggleCallMeModal()}>
          Call me
        </Button>
      </div>
    );
  }
}
