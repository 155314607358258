// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { SliderInput } from '../inputs/slider';
import { PolicyholderStore } from '../../stores/policyholder-store';

const marks = [
  {
    value: 50000,
    label: <span style={{ position: 'absolute', margin: '2px 0', left: 0 }}>
      Min R 50 000
    </span>,
  },
  {
    value: 200000,
    label: <span style={{ position: 'absolute', margin: '2px 0', right: 0 }}>
      Max R 200 000
    </span>,
  },
]

export const genders = [
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'male',
    label: 'Male'
  }
];

export const yesNoOptions = [
  {
    value: 'false',
    label: 'No'
  },
  {
    value: 'true',
    label: 'Yes'
  }
];

export const educationStatuses = [
  {
    label: 'No matric',
    value: 'no_matric'
  },
  {
    label: 'Matric',
    value: 'matric'
  },
  {
    label: '3 year degree/Diploma',
    value: '3_year_degree_diploma'
  },
  {
    label: '4 year degree',
    value: '4_year_degree'
  },
  {
    label: 'Professional',
    value: 'professional'
  }
];

interface Props {
  nextStep: () => void;
  quoteStore?: QuoteStore;
  policyholderStore?: PolicyholderStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('quoteStore', 'policyholderStore')
@observer
export class CreateQuote extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.quoteStore) {
      return;
    }

    const quoteStore = this.props.quoteStore;
    const quoteInput = quoteStore.quoteInput;
    const validationResult = quoteStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="6">
            <h1>Get a quote</h1>
          </Col>
        </Row>
        <br />
        <h5>Your details</h5>
        <br />

        <Row>
          <Col sm="6">
            <SliderInput
              id='cover_amount'
              label='Cover amount*'
              value={quoteInput.sum_assured}
              onChange={value => {
                quoteStore.quoteInput.sum_assured = value;
              }}
              invalid={!!validationResult.sum_assured}
              validationMessage={validationResult.sum_assured}
              min={50000}
              max={200000}
              step={5000}
              marks={marks}
              rightLabel={(value) => <span>
                R {value}
              </span>}
            />
          </Col>
          <Col sm="6">
            <CurrencyInput
              id='income'
              label='Basic income per month*'
              value={quoteInput.income}
              onChange={e => {
                quoteStore.quoteInput.income = parseInt(e.target.value);
              }}
              invalid={!!validationResult.income}
              validationMessage={validationResult.income}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <SelectInput
              id='education_status'
              label='Education status*'
              invalid={!!validationResult.education_status}
              validationMessage={validationResult.education_status}
              value={quoteInput.education_status}
              onChange={e => {
                quoteStore.quoteInput.education_status = e.target.value;
              }}
              options={educationStatuses}
            />
          </Col>
          <Col sm="6">
            <SelectInput
              id='gender'
              label='Gender*'
              invalid={!!validationResult.gender}
              validationMessage={validationResult.gender}
              value={quoteInput.gender}
              onChange={e => {
                quoteStore.quoteInput.gender = e.target.value;
              }}
              options={genders}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <NumberInput
              label='Age*'
              id="age"
              value={quoteInput.age}
              invalid={!!validationResult.age}
              onChange={e => {
                quoteStore.quoteInput.age = parseInt(e.target.value, 10);
              }}
              validationMessage={validationResult.age}
            />
          </Col>
          <Col sm="6">
            <SelectInput
              label="Smoker*"
              id="smoker"
              value={quoteInput.smoker}
              invalid={!!validationResult.smoker}
              validationMessage={validationResult.smoker}
              onChange={e => {
                quoteStore.quoteInput.smoker = e.target.value;
              }}
              options={yesNoOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <SelectInput
              label='Are you a South African citizen?*'
              id="southAfricanCitizen"
              value={quoteInput.south_african_citizen}
              invalid={!!validationResult.south_african_citizen}
              validationMessage={validationResult.south_african_citizen}
              onChange={e => {
                quoteStore.quoteInput.south_african_citizen = e.target.value;
              }}
              options={yesNoOptions}
            />
          </Col>
        </Row>

        {quoteInput.additional_member_included ? (
          <Card className="mt-4">
            <CardHeader>
              Additional member
              <Button
                color="link"
                className="float-right p-0"
                onClick={() => { quoteStore.removeAdditionalMember() }}
              >
                Remove
              </Button>
            </CardHeader>
            <CardBody>
              <div>
                <Row>
                  <Col sm="6">
                    <SliderInput
                      id='additional_member_cover_amount'
                      label='Cover amount*'
                      value={quoteInput.sum_assured_additional_member}
                      onChange={value => {
                        quoteStore.quoteInput.sum_assured_additional_member = value;
                      }}
                      invalid={!!validationResult.sum_assured_additional_member}
                      validationMessage={validationResult.sum_assured_additional_member}
                      min={50000}
                      max={200000}
                      step={5000}
                      marks={marks}
                      rightLabel={(value) => <span>
                        R {value}
                      </span>}
                    />
                  </Col>
                  <Col sm="6">
                    <CurrencyInput
                      id='income_additional_member'
                      label='Basic income per month*'
                      value={quoteInput.income_additional_member}
                      onChange={e => {
                        quoteStore.quoteInput.income_additional_member = parseInt(e.target.value);
                      }}
                      invalid={!!validationResult.income_additional_member}
                      validationMessage={validationResult.income_additional_member}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <SelectInput
                      id='education_status_additional_member'
                      label='Education status*'
                      invalid={!!validationResult.education_status_additional_member}
                      validationMessage={validationResult.education_status_additional_member}
                      value={quoteInput.education_status_additional_member}
                      onChange={e => {
                        quoteStore.quoteInput.education_status_additional_member = e.target.value;
                      }}
                      options={educationStatuses}
                    />
                  </Col>
                  <Col sm="6">
                    <SelectInput
                      id='gender_additional_member'
                      label='Gender*'
                      invalid={!!validationResult.gender_additional_member}
                      validationMessage={validationResult.gender_additional_member}
                      value={quoteInput.gender_additional_member}
                      onChange={e => {
                        quoteStore.quoteInput.gender_additional_member = e.target.value;
                      }}
                      options={genders}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <NumberInput
                      label='Age*'
                      id="age_additional_member"
                      value={quoteInput.age_additional_member}
                      invalid={!!validationResult.age_additional_member}
                      onChange={e => {
                        quoteStore.quoteInput.age_additional_member = parseInt(e.target.value, 10);
                      }}
                      validationMessage={validationResult.age_additional_member}
                    />
                  </Col>
                  <Col sm="6">
                    <SelectInput
                      label="Smoker*"
                      id="smoker_additional_member"
                      value={quoteInput.smoker_additional_member}
                      invalid={!!validationResult.smoker_additional_member}
                      validationMessage={validationResult.smoker_additional_member}
                      onChange={e => {
                        quoteStore.quoteInput.smoker_additional_member = e.target.value;
                      }}
                      options={yesNoOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <SelectInput
                      label='Is the person a South African citizen?*'
                      id="south_african_citizen_additional_member"
                      value={quoteInput.south_african_citizen_additional_member}
                      invalid={!!validationResult.south_african_citizen_additional_member}
                      validationMessage={validationResult.south_african_citizen_additional_member}
                      onChange={e => {
                        quoteStore.quoteInput.south_african_citizen_additional_member = e.target.value;
                      }}
                      options={yesNoOptions}
                    />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        ) : null}

        {!quoteInput.additional_member_included && (
          <div>
            <hr/>
          <Button
          color="primary"
            onClick={() => { quoteStore.addAdditionalMember() }}
          >
            + Add joint life
          </Button>
          <hr/>
          </div>
        )}
        <br></br>
        <p>Please ensure that all information supplied is accurate and complete. 
              Inaccurate information could impact on the validity of your policy and
              payment of claims
        </p>
        <Button
          color="primary"
          className="px-5"
          onClick={async () => {
            const success = await quoteStore.getQuote();
            if (success) {
              this.props.nextStep()
            }
          }}
          disabled={quoteStore.isLoading}
        >
          {quoteStore.isLoading ? 'Loading...' : 'Create quote!'}
        </Button>
        <br/>
        <br/>
        <Button
          color="primary"
          className="px-5"
          onClick={() => this.props.policyholderStore.toggleCallMeModal() }
          disabled={quoteStore.isLoading}
        >
          Call me
        </Button>
        
        <br/>
      </div>
    );
  }
}

export default CreateQuote;
