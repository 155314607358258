import React, { Component } from 'react';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import { PolicyholderStore } from '../../stores/policyholder-store';
import { inject, observer } from 'mobx-react';
import { TextInput } from '../inputs/text';
import { DateInput } from '../inputs/date';
import { countries } from '../../helpers/countries';
import { SelectInput } from '../inputs/select';

interface Props {
  nextStep: () => void;
  previousStep: () => void;
  policyholderStore?: PolicyholderStore;
}

@inject('policyholderStore')
@observer
class CreatePolicyholder extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {}
    };
  }

  continue() {
    this.props.policyholderStore.validate();
    if (!this.props.policyholderStore.hasValidationError()) {
      this.props.nextStep();
    }
  }

  render() {
    if (!this.props.policyholderStore) {
      return null;
    }

    const policyholderInput = this.props.policyholderStore.policyholderInput;
    const validationResult = this.props.policyholderStore.validationResult;

    return (
      <div>
        <h1>Policyholder</h1>
        <br />
        <h4>Please complete your details</h4>
        <br />

        <Row>
          <Col sm="6">
            <TextInput
              label="First name*"
              invalid={!!validationResult.first_name}
              validationMessage={validationResult.first_name}
              value={policyholderInput.first_name}
              onChange={e => {
                policyholderInput.first_name = e.target.value;
              }}
              id="first_name"
            />
          </Col>
          <Col sm="6">
            <TextInput
              label="Last name*"
              invalid={!!validationResult.last_name}
              validationMessage={validationResult.last_name}
              value={policyholderInput.last_name}
              onChange={e => {
                policyholderInput.last_name = e.target.value;
              }}
              id="last_name"
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <TextInput
              label="Email*"
              invalid={!!validationResult.email}
              validationMessage={validationResult.email}
              value={policyholderInput.email}
              onChange={e => {
                policyholderInput.email = e.target.value;
              }}
              id="email"
            />
          </Col>
          <Col sm="6">
            <TextInput
              label="Cellphone*"
              invalid={!!validationResult.cellphone}
              validationMessage={validationResult.cellphone}
              value={policyholderInput.cellphone}
              onChange={e => {
                policyholderInput.cellphone = e.target.value;
              }}
              id="cellphone"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <b>Type of identification</b>
            </p>
            <FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="radioID"
                    onChange={() => {}}
                    checked={policyholderInput.id_type !== 'passport'}
                    onClick={() => {
                      policyholderInput.id_type = 'id';
                      policyholderInput.id_country = 'ZA';
                      this.props.policyholderStore.validationResult = {};
                    }}
                  />{' '}
                  South African ID number
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <TextInput
              label={
                policyholderInput.id_type === 'id'
                  ? 'ID number*'
                  : 'Passport number*'
              }
              invalid={!!validationResult.id_number}
              validationMessage={validationResult.id_number}
              value={policyholderInput.id_number}
              onChange={e => {
                policyholderInput.id_number = e.target.value;
              }}
              id="id_number"
            />
          </Col>
          {policyholderInput.id_type === 'passport' && (
            <>
              <Col sm="6">
                <DateInput
                  label="Date of birth*"
                  invalid={!!validationResult.date_of_birth}
                  validationMessage={validationResult.date_of_birth}
                  value={policyholderInput.date_of_birth}
                  onChange={e => {
                    policyholderInput.date_of_birth = e.target.value;
                  }}
                  id="date_of_birth"
                />
              </Col>
              <Col sm="6">
                <SelectInput
                  label="Country*"
                  invalid={!!validationResult.id_country}
                  validationMessage={validationResult.id_country}
                  value={policyholderInput.id_country}
                  onChange={e => {
                    policyholderInput.id_country = e.target.value;
                  }}
                  id="id_country"
                  options={countries}
                />
              </Col>
            </>
          )}
        </Row>
        <Button
          color="primary"
          className="px-5"
          onClick={this.continue.bind(this)}
        >
          Continue
        </Button>
        <Button color="link" onClick={this.props.previousStep}>
          Back
        </Button>
        <br />
        <br />
        <Button
          color="primary"
          className="px-5"
          onClick={() => this.props.policyholderStore.toggleCallMeModal()}
        >
          Call me
        </Button>
      </div>
    );
  }
}

export default CreatePolicyholder;
