
const moment = require('moment');

const zarString = (amount) =>
  `R${((parseInt(amount) / 100.0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

const ageString = (dateOfBirth) => {
  const age = moment().diff(moment(dateOfBirth), 'years');
  return `${age} years old`;
};

const upperCaseFirstLetter = s => s.charAt(0).toUpperCase() + s.substr(1);

module.exports = {
  zarString,
  ageString,
  upperCaseFirstLetter
};
