import moment from 'moment'
import { ageFromDateOfBirth, ageFromID } from "./age";

export const validateName = value => {
  return value && value.length > 0;
};

export const validateAmount = value => {
  return value && value > 0;
};

export const validateDateOfBirth = (value, minAge, maxAge) => {
  if (!value) {
    return false;
  }
  if (minAge && ageFromDateOfBirth(value) < minAge) {
    return false;
  }
  if (maxAge && ageFromDateOfBirth(value) > maxAge) {
    return false;
  }
  return true;
};

export const validateEmail = value => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return value && re.test(String(value).toLowerCase());
};

export const validateCellphone = value => {
  if (!value || !value.match(/^((\+[0-9\s]+)|([0-9\s]+))$/)) {
    return false;
  }

  const cleanNumber = value.replace(/\D/g, "").replace(/^(0+|27)/, "");
  if (cleanNumber.length !== 9) {
    return false;
  } else if (!cleanNumber.match(/^[1-8]/)) {
    return false;
  } else {
    return true;
  }
};

export const validateIdNumber = value => {
  if (
    /[0-9]+/.test(value) &&
    value.length === 13 &&
    moment(value.substring(0, 6), "YYMMDD", true).isValid()
  ) {
    let nCheck = 0;
    let nDigit = 0;
    let bEven = false;

    for (var n = value.length - 1; n >= 0; n--) {
      const cDigit = value.charAt(n);
      nDigit = parseInt(cDigit, 10);
      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }
      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  }
};

export const validateGender = value => {
  return ["male", "female"].includes(value);
};

// Checks whether all fields are true
const allValid = value => {
  if (value instanceof Array) {
    return value.reduce((acc, curr) => acc && allValid(curr), true);
  } else if (value instanceof Object) {
    return Object.values(value).reduce(
      (acc, curr) => acc && allValid(curr),
      true
    );
  } else {
    return !!value;
  }
};

export const validateAge = (age, min, max) => {
  const intAge = parseInt(age);
  if (typeof intAge === 'undefined') {
    return false;
  }

  if (intAge < min) {
    return false;
  }

  if (intAge > max) {
    return false;
  }

  return true;
};

export const validateAgeAgainstIDNumber = (age: number, idNumber: string) => {
  const idAge = ageFromID(idNumber)

  return idAge === age ? '' : `Age from ID number does not match the quoted age ${age}`
}
