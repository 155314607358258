import { observable } from 'mobx';
import { validateName } from '../helpers/validation';
import { calculateMonthlyProRata, calculateYearlyProRata } from '../helpers/pro-rata';

export class PaymentDetailsStore {
  @observable paymentMethodInput = {
    type: 'debit_order',
    bank_details_account_holder: '',
    bank_details_bank_name: '',
    bank_details_branch_code: '',
    bank_details_account_number: '',
    billing_day: '1'
  };

  @observable validationResult = {
    type: '',
    bank_details_account_holder: '',
    bank_details_bank_name: '',
    bank_details_branch_code: '',
    bank_details_account_number: '',
    billing_day: ''
  };

  validate = () => {
    const {
      bank_details_account_holder,
      bank_details_account_number,
      bank_details_bank_name,
      bank_details_branch_code
    } = this.paymentMethodInput
    this.validationResult.bank_details_bank_name = !bank_details_bank_name ? 'Select a bank' : ''

    this.validationResult.bank_details_account_holder = !validateName(bank_details_account_holder) ? 'Enter a valid name' : ''

    this.validationResult.bank_details_account_number = !bank_details_account_number ? 'Enter a valid account number' : ''
    this.validationResult.bank_details_branch_code = !bank_details_branch_code ? 'Enter a valid account number' : ''
  }

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getApiInput = () => {
    return {
      type: this.paymentMethodInput.type,
      bank_details: {
        account_holder: this.paymentMethodInput.bank_details_account_holder,
        account_number: this.paymentMethodInput.bank_details_account_number,
        branch_code: this.paymentMethodInput.bank_details_branch_code,
        bank: this.paymentMethodInput.bank_details_bank_name,
      }
    }
  }

  getProRataAmount = (premium: number, billingFrequency) => {
    if (billingFrequency === 'monthly') {
      return calculateMonthlyProRata(premium, parseInt(this.paymentMethodInput.billing_day))
    }

    if (billingFrequency === 'yearly') {
      return calculateYearlyProRata(premium)
    }
  }

}

export const paymentDetailsStore = new PaymentDetailsStore();
