/* global alert:false */
import React, { Component } from 'react';
import { Button, Table, Row, Col, Collapse, Card, CardBody } from 'reactstrap';
import { zarString } from '../../helpers/format';
import { banks, allowedRelationships } from '../../helpers/data';
import api from '../../api';
import SignaturePad from 'signature_pad/dist/signature_pad.js';
import { inject, observer } from 'mobx-react';
import { PolicyholderStore } from '../../stores/policyholder-store';
import { QuoteStore } from '../../stores/quote-store';
import { PaymentDetailsStore } from '../../stores/payment-details-store';
import { ApplicationStore } from '../../stores/application-store';
import { PolicyStore } from '../../stores/policy-store';
import * as _ from 'lodash';
import { BillingFrequency } from './quote-summary';
import { BeneficiariesStore } from '../../stores/beneficiaries-store';
let signaturePad;

interface Props {
  nextStep: () => void;
  previousStep: () => void;
  policyholderStore?: PolicyholderStore;
  quoteStore?: QuoteStore;
  paymentDetailsStore?: PaymentDetailsStore;
  applicationStore?: ApplicationStore;
  policyStore?: PolicyStore;
  beneficiariesStore?: BeneficiariesStore;
}

interface State {
  loadingPercentage: number;
  showFullDeclaration: boolean;
  policyholder: any;
  loading: boolean;
}

@inject(
  'policyholderStore',
  'quoteStore',
  'paymentDetailsStore',
  'policyStore',
  'applicationStore',
  'beneficiariesStore',
)
@observer
class CheckoutSummary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      policyholder: {},
      loadingPercentage: 0,
      showFullDeclaration: false,
      loading: false,
    };
  }

  componentDidMount() {
    const canvas = document.querySelector('canvas');
    signaturePad = new SignaturePad(canvas);
    signaturePad.clear();
  }

  clearSignature(e) {
    e.preventDefault();
    signaturePad.clear();
  }

  getRelationString(member) {
    switch (member.type) {
      case 'policyholder':
        return 'You';
      case 'child':
        return 'Your child';
      case 'spouse':
        return 'Your spouse';
      default:
        const memberType = allowedRelationships.find(m => m.key === member.type);
        return memberType ? `Your ${memberType.val.toLowerCase()}` : '-';
    }
  }

  async issuePolicy() {
    if (signaturePad.isEmpty()) {
      return alert('Please sign before we can issue the policy!');
    }

    this.setState({ loading: true, loadingPercentage: 5 });

    if (!this.props.policyholderStore) {
      console.error('policyholder store was not initialized');
      return;
    }

    const policyholderToCreate = this.props.policyholderStore.getPolicyholderApiInput();
    try {
      // Create policyholder
      const policyholder = await api.createPolicyholder(policyholderToCreate).catch(async err => {
        if (err.error && err.error.type === 'conflict_error') {
          console.error(
            'Only one policy allowed per policyholder through the website. Please contact the call center.',
          );
          return Promise.reject(new Error('Only one policy allowed per policyholder through the website. Please contact the call center.'));
        } else {
          console.error('Creating policyholder failed:', err);
          return Promise.reject(new Error('Could not create policyholder!'));
        }
      });
      this.setState({ loadingPercentage: 20 });

      const selectedPackage = this.props.quoteStore.getSelectedQuotePackage();
      // Create application
      const applicationData = {
        quote_package_id: selectedPackage.quote_package_id,
        policyholder_id: policyholder.policyholder_id,
        ...this.props.applicationStore.getApiInput(),
      };
      const application = await api.createApplication(applicationData).catch(err => {
        console.error('Creating application failed:', err);
        return Promise.reject(new Error('Could not create application!'));
      });
      this.setState({ loadingPercentage: 40 });

      // Create policy
      const policy = await api
        .createPolicy({
          application_id: application.application_id,
          billing_day: parseInt(this.props.paymentDetailsStore.paymentMethodInput.billing_day),
        })
        .catch(err => {
          console.error('Creating policy failed:', err);
          return Promise.reject(new Error('Could not create policy!'));
        });
      this.setState({ loadingPercentage: 60 });

      const paymentMethod = this.props.paymentDetailsStore.getApiInput();
      // Add payment method
      await api
        .addPaymentMethod(policyholder.policyholder_id, {
          ...paymentMethod,
          policy_ids: [policy.policy_id],
        })
        .catch(err => {
          console.error('Adding payment method failed:', err);
          return Promise.reject(new Error('Could not add payment method!'));
        });
      this.setState({ loadingPercentage: 75 });

      const beneficiaries = this.props.beneficiariesStore.getApiInput();

      if (beneficiaries.length) {
        await api.createBeneficiaries(policy.policy_id, this.props.beneficiariesStore.getApiInput()).catch(err => {
          console.error('Adding beneficiaries failed:');
          console.error(err);
          return Promise.reject(new Error('Adding beneficiaries failed'));
        });
        this.setState({ loadingPercentage: 90 });
      }

      // Upload signature
      await api
        .createAttachment(policy.policy_id, {
          file_name: 'Authorisation Signature.png',
          description: 'Policy and debit order authorisation signature.',
          file_base64: signaturePad.toDataURL('image/png').slice('data:image/png;base64,'.length),
          file_type: 'image/png',
        })
        .catch(err => {
          console.error('Adding signature failed:', err);
          return Promise.reject(new Error('Could not add signature!'));
        });
      this.setState({ loadingPercentage: 100 });

      // Show success page

      this.props.policyStore.createdPolicy = policy;
      this.props.policyholderStore.createdPolicyholder = policyholder;

      this.props.nextStep();
    } catch (error) {
      this.setState({ loading: false });
      return alert('Something went wrong. ' + error.message);
    }
  }

  getPremium() {
    const premium = this.props.quoteStore.getSelectedQuotePackage().suggested_premium;
    const billingFrequency = this.props.quoteStore.getSelectedQuotePackage().billing_frequency;

    let resultingAmount = '';
    switch (billingFrequency) {
      case BillingFrequency.Yearly: {
        resultingAmount = zarString(premium * 12).replace('R', '');
        break;
      }
      default: {
        resultingAmount = zarString(premium).replace('R', '');
      }
    }

    return resultingAmount;
  }

  renderPremium() {
    const billingFrequency = this.props.quoteStore.getSelectedQuotePackage().billing_frequency;
    const amount = this.getPremium();
    return (
      <div>
        <h5>{_.startCase(billingFrequency)} premium</h5>
        <div className='premium'>
          <span className='currency'>
            <b>R</b>
          </span>
          <span className='amount'>
            <b>{amount}</b>
          </span>
        </div>
        <br />
        <p>
          {billingFrequency === BillingFrequency.Yearly ? (
            <div>
              <b>Payment date: </b>1st of January each year{' '}
            </div>
          ) : (
            <div>
              <b>Payment date: </b>1st of each month{' '}
            </div>
          )}
        </p>
      </div>
    );
  }

  renderRow(name, age, educationStatus, index, coverAmount, billingFrequency) {
    return (
      <tr key={`${name}-${index || ''}`}>
        <td>
          <b>{name}</b>
        </td>
        <td>{age}</td>
        <td>{_.startCase(educationStatus)}</td>
        <td>{zarString(coverAmount)}</td>
      </tr>
    );
  }

  renderTable() {
    const quote = this.props.quoteStore.getSelectedQuotePackage();
    return (
      <Table bordered>
        <thead>
          <tr className='table-active'>
            <th colSpan={5}>Included in quote</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td>
              <strong>Age</strong>
            </td>
            <td>
              <strong>Education status</strong>
            </td>
            <td>
              <strong>Sum Assured</strong>
            </td>
          </tr>
          {this.renderRow(
            'Policyholder',
            quote.module.age,
            quote.module.education_status,
            0,
            quote.module.sum_assured,
            quote.module.billing_frequency,
          )}
          {quote.module.additional_member_included &&
            this.renderRow(
              'Additional member',
              quote.module.age_additional_member,
              quote.module.education_status_additional_member,
              1,
              quote.module.sum_assured_additional_member,
              quote.module.billing_frequency,
            )}
        </tbody>
      </Table>
    );
  }

  renderPaymentDetails() {
    const paymentMethod = this.props.paymentDetailsStore.paymentMethodInput;
    const bank = banks.find(b => b.value === paymentMethod.bank_details_bank_name);

    const { bank_details_account_holder, bank_details_account_number, bank_details_branch_code } = paymentMethod;

    return (
      <div>
        <h5>Payment details</h5>
        <br />
        {paymentMethod.type === 'debit_order' ? (
          <Table borderless style={{ lineHeight: 0 }}>
            <tbody>
              <tr>
                <td style={{ paddingLeft: 0 }}>
                  <b>Payment method</b>
                </td>
                <td>Debit order</td>
              </tr>
              <tr>
                <td style={{ paddingLeft: 0 }}>
                  <b>Bank</b>
                </td>
                <td>{bank && bank.label}</td>
              </tr>
              <tr>
                <td style={{ paddingLeft: 0 }}>
                  <b>Account number</b>
                </td>
                <td>{bank_details_account_number}</td>
              </tr>
              <tr>
                <td style={{ paddingLeft: 0 }}>
                  <b>Account holder</b>
                </td>
                <td>{bank_details_account_holder}</td>
              </tr>
              <tr>
                <td style={{ paddingLeft: 0 }}>
                  <b>Branch code</b>
                </td>
                <td>{bank_details_branch_code}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <p>TODO</p>
        )}
      </div>
    );
  }

  renderDeclaration() {
    const policyholder = this.props.policyholderStore.policyholderInput;
    return (
      <div>
        <h5>Declaration</h5>
        <br />
        <p>
          I {policyholder.firstName} {policyholder.lastName}, {policyholder.id_type === 'id' ? 'ID' : 'passport'} number{' '}
          {policyholder.id_number}, confirm that:
        </p>
        <ul>
          <li>The information in this application is true and correct; and</li>
          <li>
            I have read and agree to abide by the terms set out in the{' '}
            <a target='_blank' href='/terms.pdf'>
              policy document
            </a>
            ; and
          </li>
          <li>
            I am aware of no other information which might be relevant to Old Mutual Alternative Risk Transfer Limited
            (OMART's) decision to offer me cover.
          </li>
          <li>
            I hereby authorise Shackleton Life, on behalf of OMART, to issue a payment instruction to collect the
            monthly premium by debit order from my bank account.{' '}
            <a
              href='#'
              onClick={e => {
                e.preventDefault();
                this.setState({
                  showFullDeclaration: !this.state.showFullDeclaration,
                });
              }}
            >
              View full declaration
            </a>
            .
          </li>
        </ul>
      </div>
    );
  }

  renderFullDeclaration() {
    return (
      <div>
        <Collapse isOpen={this.state.showFullDeclaration}>
          <Card>
            <CardBody>
              <b>Full declaration</b>
              <p>
                I hereby authorise Shackleton Life, on behalf of OMART, to issue a payment instruction to collect the
                monthly premium by debit order from my bank account on condition that the sum of such payment
                instruction will never exceed my obligation in terms of this application.
              </p>

              <p>
                I acknowledge that all payment instructions issued by Shackleton Life, on behalf of OMART shall be
                treated by my bank as if the instruction has been issued by me personally. The debit order will be
                collected every month on the debit order collection date selected (as confirmed above). In the event
                that this collection day falls on a Sunday or a recognised South African public holiday, the collection
                day will automatically be the next ordinary business day.
              </p>

              <p>
                I acknowledge that this authority may be assigned to a third party only if the policy is transferred to
                another insurer or administrator. I understand that the payment instruction will be processed through a
                computerised system provided by the South African Banks.
              </p>

              <p>
                I shall not be entitled to any refund of amounts which Shackleton Life as the payment distribution agent
                on behalf of OMART has collected while this debit order authority was in force if such amounts were
                legally owing to OMART.
              </p>

              <p>
                This authority may be cancelled by giving Shackleton Life notice of not less than 30 days and such
                cancellations will not necessarily cancel my policy.
              </p>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }

  render() {
    if (
      !this.props.policyStore ||
      !this.props.policyholderStore ||
      !this.props.paymentDetailsStore ||
      !this.props.quoteStore ||
      !this.props.applicationStore
    ) {
      return null;
    }

    return (
      <div>
        <h1>Policy confirmation</h1>
        <br />
        <h5>Please check all your policy details before checking out.</h5>
        <br />
        <hr />
        <br />
        {/* Payment section */}
        <Row>
          <Col sm={6}>{this.renderPremium()}</Col>
          <Col sm={6}>{this.renderPaymentDetails()}</Col>
        </Row>
        <br />
        {this.renderTable()}
        <br />
        {this.renderDeclaration()}
        <br />
        {this.renderFullDeclaration()}
        <br />
        <h5 style={{ marginBottom: '12px' }}>Sign in the box below with your mouse or touchscreen to accept policy</h5>
        <canvas className='signature-canvas' />
        <Button color='link' onClick={this.clearSignature} style={{ paddingLeft: 0 }}>
          Clear
        </Button>

        <br />
        <br />
        <Button color='primary' className='px-5' onClick={this.issuePolicy.bind(this)} disabled={this.state.loading}>
          {this.state.loading ? 'Loading...' : 'Take out policy'}
        </Button>
        <Button color='link' onClick={this.props.previousStep}>
          Back
        </Button>

        {/* Loader */}
        <FullScreenLoader active={this.state.loading} percentage={this.state.loadingPercentage} />
      </div>
    );
  }
}

interface FullScreenLoaderProps {
  active: boolean;
  percentage: number;
}
class FullScreenLoader extends Component<FullScreenLoaderProps> {
  render() {
    return (
      <div>
        <section className={'full-screen-loader' + (this.props.active ? ' active' : '')}>
          <div>
            <h3>Busy issuing your new policy...</h3>
            <div className='line'>
              <div className='loader' style={{ width: `${this.props.percentage || 0}%` }} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CheckoutSummary;
