import * as React from 'react';
import { BeneficiariesStore } from '../../stores/beneficiaries-store';
import { inject, observer } from 'mobx-react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { PolicyholderStore } from '../../stores/policyholder-store';
import { TextInput } from '../inputs/text';
import { countries } from '../../helpers/countries';
import { SelectInput } from '../inputs/select';
import { allowedRelationships } from '../../helpers/data';
import { DateInput } from '../inputs/date';
import { SliderInput } from '../inputs/slider';

interface Props {
  beneficiariesStore?: BeneficiariesStore;
  policyholderStore?: PolicyholderStore;
  previousStep: () => void;
  nextStep: () => void;
}
@inject('beneficiariesStore')
@observer
export class CreateBeneficiaries extends React.Component<Props> {
  render() {
    const validationResult = this.props.beneficiariesStore.validationResult;
    const beneficiariesInput = this.props.beneficiariesStore.inputBeneficiaries;
    return (
      <div>
        <h1>Add beneficiaries</h1>
        <br />
        <p>
          Should anything happen to you, it is important that the proceeds of your cover pass seamlessly to your loved ones,
          which is why we advise that you add your beneficiaries now.
        </p>
        <br />
        {
          this.props.beneficiariesStore.inputBeneficiaries.length === 0 &&
          <Button
            onClick={this.props.beneficiariesStore.add}
            color="primary"
          >
            Add beneficiary
          </Button>
        }

        <hr />
        <br />


        {
          beneficiariesInput.map((beneficiary, index) => {
            return <div>
              <Row>
                <Col sm='9'></Col>
                <Col sm='3'>
                  <Button
                    color='link'
                    onClick={() => this.props.beneficiariesStore.remove(index)}
                    style={{
                      float: 'right'
                    }}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <TextInput
                    label="First name*"
                    invalid={!!validationResult[index].first_name}
                    validationMessage={validationResult[index].first_name}
                    value={beneficiariesInput[index].first_name}
                    onChange={e => {
                      beneficiariesInput[index].first_name = e.target.value;
                    }}
                    id="first_name"
                  />
                </Col>
                <Col sm='6'>
                  <TextInput
                    label="Last name*"
                    invalid={!!validationResult[index].last_name}
                    validationMessage={validationResult[index].last_name}
                    value={beneficiariesInput[index].last_name}
                    onChange={e => {
                      beneficiariesInput[index].last_name = e.target.value;
                    }}
                    id="last_name"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <TextInput
                    label="Email address*"
                    invalid={!!validationResult[index].email}
                    validationMessage={validationResult[index].email}
                    value={beneficiariesInput[index].email}
                    onChange={e => {
                      beneficiariesInput[index].email = e.target.value;
                    }}
                    id="email"
                  />
                </Col>
                <Col sm="6">
                  <SliderInput
                    id='percentage'
                    label='Percentage*'
                    value={beneficiariesInput[index].percentage}
                    onChange={value => {
                      beneficiariesInput[index].percentage = value;
                    }}
                    invalid={!!validationResult[index].percentage}
                    validationMessage={validationResult[index].percentage}
                    min={0}
                    max={100}
                    step={1}
                    marks={[]}
                    rightLabel={(value) => <span>
                      {value} %
                    </span>}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <SelectInput
                    label="Relationship*"
                    invalid={!!validationResult[index].relationship}
                    validationMessage={validationResult[index].relationship}
                    value={beneficiariesInput[index].relationship}
                    onChange={e => {
                      beneficiariesInput[index].relationship = e.target.value;
                    }}
                    id="relationship"
                    options={allowedRelationships}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <b>Type of identification</b>
                  </p>
                  <FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type='radio'
                          name={`radio_id-${index}`}
                          checked={beneficiariesInput[index].id_type === 'id'}
                          onChange={() => { }}
                          onClick={() => {
                            beneficiariesInput[index].id_type = 'id'
                            this.props.beneficiariesStore.validate()
                          }}
                        />{' '}
                      South African ID number
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type='radio'
                          name={`radio_passport-${index}`}
                          checked={beneficiariesInput[index].id_type === 'passport'}
                          onClick={() => {
                            beneficiariesInput[index].id_type = 'passport'
                            this.props.beneficiariesStore.validate()
                          }}
                        />{' '}
                        Passport number
                        </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <FormGroup>
                    <TextInput
                      label={`${beneficiariesInput[index].id_type === 'id' ? 'ID' : 'Passport'} number*`}
                      invalid={!!validationResult[index].id_number}
                      validationMessage={validationResult[index].id_number}
                      value={beneficiariesInput[index].id_number}
                      onChange={e => {
                        beneficiariesInput[index].id_number = e.target.value;
                      }}
                      id="id_number"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {
                beneficiariesInput[index].id_type === 'passport' &&
                <Row>
                  <Col sm='6'>
                    <SelectInput
                      label="Country*"
                      invalid={!!validationResult[index].id_country}
                      validationMessage={validationResult[index].id_country}
                      value={beneficiariesInput[index].id_country}
                      onChange={e => {
                        beneficiariesInput[index].id_country = e.target.value;
                      }}
                      id="id_country"
                      options={countries}
                    />
                  </Col>
                  <Col sm='6'>
                    <DateInput
                      label='Date of birth*'
                      invalid={!!validationResult[index].date_of_birth}
                      validationMessage={validationResult[index].date_of_birth}
                      value={beneficiariesInput[index].date_of_birth}
                      onChange={e => { beneficiariesInput[index].date_of_birth = e.target.value }}
                      id='date_of_birth'
                    />
                  </Col>
                </Row>

              }
              <hr />
            </div>
          })

        }

        {
          this.props.beneficiariesStore.inputBeneficiaries.length !== 0 &&
          <div>
            <Button
              onClick={this.props.beneficiariesStore.add}
              color="primary"
            >
              Add another
            </Button>
            <hr />
            <br />
          </div>
        }


        <Button
          color="primary"
          className="px-5"
          onClick={() => {
            this.props.beneficiariesStore.validate()
            if (this.props.beneficiariesStore.hasValidationError()) {
              return
            }
            this.props.nextStep()
          }}
        >
          Continue
        </Button>
        <Button color="link" onClick={this.props.previousStep}>
          Back
        </Button>
        <br />
        <br />
        <Button
          color="primary"
          className="px-5"
          onClick={() => this.props.policyholderStore.toggleCallMeModal()}
        >
          Call me
        </Button>
      </div>
    )
  }
}