import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { PolicyStore } from '../../stores/policy-store';

interface Props {
  policyStore?: PolicyStore;
}

@inject('policyStore')
@observer
class PolicyIssued extends Component<Props> {
  render () {
    return <div>
      <h1>Policyholder</h1>
      <br />
      <h5>Congratulations! Your Shackleton Quick Claim policy was successfully issued!</h5>
      <br />
      <h5>Policy number</h5>
      <span className='policy-number'>{this.props.policyStore.createdPolicy.policy_number}</span>
      <br />
      <p>Check your email for your email policy confirmation and documents.</p>
    </div>;
  }
}

export default PolicyIssued;
