import { observable } from 'mobx';
import {
  validateName,
  validateEmail,
  validateCellphone,
  validateIdNumber,
  validateDateOfBirth,
  validateAgeAgainstIDNumber
} from '../helpers/validation';
import { quoteStore } from './quote-store';
import {utc as moment} from 'moment';
import { replace } from 'lodash';

export class ApplicationStore {
  @observable applicationInput: any = {
    additional_member_id_type: 'id',
    additional_member_id_country: 'ZA',
  };
  @observable validationResult: any = {
    preffered_method_to_receive_information: '',
    replacement_policy: '',
    additional_member_first_name: '',
    additional_member_last_name: '',
    additional_member_cellphone: '',
    additional_member_date_of_birth: '',
    additional_member_email: '',
    additional_member_relationship: '',

    additional_member_id_type: '',
    additional_member_id_number: '',
    additional_member_id_country: ''
  };

  validate = () => {
    this.validationResult = {};
    const {
      preffered_method_to_receive_information,
      replacement_policy,
      additional_member_first_name,
      additional_member_last_name,
      additional_member_email,
      additional_member_cellphone,
      additional_member_date_of_birth,
      additional_member_id_number,
      additional_member_id_type,
      additional_member_id_country,
      additional_member_relationship
    } = this.applicationInput;

    this.validationResult.preffered_method_to_receive_information = !preffered_method_to_receive_information
      ? 'Select your preferred method'
      : '';

      this.validationResult.replacement_policy = !replacement_policy
      ? 'Select if this is a replacement policy'
      : '';

    if (
      quoteStore.getSelectedQuotePackage().module.additional_member_included
    ) {
      this.validationResult.additional_member_first_name = !validateName(
        additional_member_first_name
      )
        ? 'Enter a valid name.'
        : '';
      this.validationResult.additional_member_last_name = !validateName(
        additional_member_last_name
      )
        ? 'Enter a valid name.'
        : '';
      this.validationResult.additional_member_email = !validateEmail(
        additional_member_email
      )
        ? 'Enter a valid email.'
        : '';
      this.validationResult.additional_member_cellphone = !validateCellphone(
        additional_member_cellphone
      )
        ? 'Enter a valid cellphone number.'
        : '';

      this.validationResult.additional_member_id_number =
        additional_member_id_type === 'id'
          ? !validateIdNumber(additional_member_id_number)
            ? 'Enter a valid SA identification number.'
            : validateAgeAgainstIDNumber(quoteStore.quoteInput.age_additional_member || 0, additional_member_id_number)
          : !additional_member_id_number
          ? 'Enter a valid passport number.'
          : '';

      this.validationResult.additional_member_relationship = !additional_member_relationship ? 'Select the relationship to you' : ''

      if (additional_member_id_type === 'passport') {
        this.validationResult.additional_member_date_of_birth = !validateDateOfBirth(
          additional_member_date_of_birth,
          17,
          59
        )
          ? 'Age can range between 17 and 59'
          : '';
        this.validationResult.additional_member_id_country =
          additional_member_id_country !== 'ZA'
            ? 'This product is only eligible to RSA citizens residing in the republic and in possession of a valid RSA ID number.'
            : '';
      }
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getApiInput = () => {
    const {
      additional_member_first_name,
      additional_member_last_name,
      additional_member_email,
      additional_member_cellphone,
      additional_member_date_of_birth,
      additional_member_id_number,
      additional_member_id_type,
      additional_member_relationship,
      preffered_method_to_receive_information,
      replacement_policy,
    } = this.applicationInput;

    return {
      start_date: moment().format("YYYY-MM-DD"),
      acceptance_date: moment().format("YYYY-MM-DD"),
      replacement_policy: replacement_policy === 'true' ? true : false,
      agent_name_surname: 'quick claim site',
      preffered_method_to_receive_information,
      reinstatement: false,
      application_reference: 'Digital Portal',
      additional_member: {
        relationship: additional_member_relationship,
        first_name: additional_member_first_name,
        last_name: additional_member_last_name,
        email: additional_member_email,
        cellphone: additional_member_cellphone,
        ...(additional_member_id_type === 'passport'
          ? { additional_member_date_of_birth: moment(additional_member_date_of_birth).format('YYYYMMDD') }
          : {}),
        id: {
          type: additional_member_id_type,
          number: additional_member_id_number
      }
      }
      
    };
  };

}

export const applicationStore = new ApplicationStore();
