import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Routes
import Landing from './components/landing';
import Application from './components/application';

ReactDOM.render(<BrowserRouter>
  <div>
    <Route exact path='/' component={Landing} />
    <Route path='/apply' component={Application} />
  </div>
</BrowserRouter>, document.getElementById('root'));
