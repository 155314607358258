import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import { zarString } from '../../helpers/format';
import { QuoteStore } from '../../stores/quote-store';
import { inject, observer } from 'mobx-react';
import * as _ from 'lodash';
import { PolicyholderStore } from '../../stores/policyholder-store';

interface Props {
  quoteStore?: QuoteStore;
  policyholderStore?: PolicyholderStore;
  previousStep: () => void;
  nextStep: () => void;
}

interface State { }

export enum BillingFrequency {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

@inject('quoteStore', 'policyholderStore')
@observer
class QuoteSummary extends Component<Props, State> {

  setSelectedQuoteIndex = selectedIndex => {
    this.props.quoteStore.setSelectedQuoteIndex(selectedIndex);
  };

  getPremium(premium, billingFrequency) {
    let resultingAmount = '';
    switch (billingFrequency) {
      case BillingFrequency.Yearly: {
        resultingAmount = zarString(premium * 12).replace('R', '');
        resultingAmount += ' per year';
        break;
      }
      default: {
        resultingAmount = zarString(premium).replace('R', '');
        resultingAmount += ' per month';
      }
    }

    return resultingAmount;
  }

  renderPremium(premium, billingFrequency) {
    const amount = this.getPremium(premium, billingFrequency);
    return (
      <div className="premium">
        <span className="currency">
          <b>R {}</b>
        </span>
        <span className="amount">
          <b>{amount}</b>
        </span>
      </div>
    );
  }

  renderRow(name, age, educationStatus, index, coverAmount, billingFrequency) {
    return (
      <tr key={`${name}-${index || ''}`}>
        <td>
          <b>{name}</b>
        </td>
        <td>{age}</td>
        <td>{_.startCase(educationStatus)}</td>
        <td>{zarString(coverAmount)}</td>
      </tr>
    );
  }

  renderTable(quote) {
    return (
      <Table bordered>
        <thead>
          <tr className="table-active">
            <th colSpan={5}>Included in {quote.package_name} package</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td>
              <strong>Age</strong>
            </td>
            <td>
              <strong>Education status</strong>
            </td>
            <td>
              <strong>Sum Assured</strong>
            </td>
          </tr>
          {this.renderRow(
            'Policyholder',
            quote.module.age,
            quote.module.education_status,
            0,
            quote.module.sum_assured,
            quote.module.billing_frequency
          )}
          {quote.module.additional_member_included &&
            this.renderRow(
              'Additional member',
              quote.module.age_additional_member,
              quote.module.education_status_additional_member,
              1,
              quote.module.sum_assured_additional_member,
              quote.module.billing_frequency
            )}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <div>
        <h1>Quotes</h1>
        <p>Select monthly or annual premium option by clicking on the preferred box below</p>
        <br />
        <h5>Premium</h5>
        {this.props.quoteStore.quoteResults.map((quote, index) => (
          <div
            className={`quote-option${
              index === this.props.quoteStore.selectedQuoteIndex
                ? '-active'
                : ''
              }`}
            onClick={() => this.props.quoteStore.setSelectedQuoteIndex(index)}
          >
            {this.renderPremium(
              quote.suggested_premium,
              quote.billing_frequency
            )}
            {this.renderTable(quote)}
          </div>
        ))}
        <br />
        <Button color="primary" onClick={this.props.previousStep}>
          Get another quote
        </Button>
        <hr />
        <h5>
          Before you proceed, there is some important information you need to
          take note of:
        </h5>
        <p>
          Unfortunately, no benefit can be paid if the death results from the
          specific behaviors, waiting periods or events listed here;
        </p>
        <br />
        <br />
        <h5>Exclusions</h5>
        <p>
          Old Mutual Alternative Risk Transfer Limited will not be obliged
          to make payment in respect of any condition or event arising
          directly or indirectly from or traceable to;
        </p>
        <p>
          <strong>Suicide or Suicide Attempt</strong>
        </p>
        <ol type="a">
          <li>
            If the Life Assured dies, whether sane or insane, by his own
            intentional act within 24 months of the Commencement of Insurance or
            date of any reinstatement no claim shall be considered or paid and
            all premiums paid to date shall be forfeited.
          </li>
          <li>
            In the event of any increase in the amount of any benefit due to any
            subsequent written request the aforementioned stipulation shall
            apply for a further 24 months from the date of such increase in
            respect of the increased portion of the benefit
          </li>
        </ol>
        <p>
          <strong>Hazardous Sport /Activity;</strong>
        </p>
        <p>The Life Assured participating in a Hazardous Sport / Activity as listed;</p>
        <p>
          “Hazardous Sport / Activity” include but are not limited to
          participation in the following sports: aviation sports, paragliding,
          underwater diving necessitating the use of an artificial breathing
          apparatus, hang-gliding, hunting, spear-fishing, rock-climbing or
          mountaineering necessitating the use of ropes and/or guides, free
          climbing, open water swimming, micro-lighting, motorboat racing, motor
          racing, motor-cycle racing, sky diving/parachuting, target shooting,
          acrobatic flying, parasailing, go-carting, drag-racing, rally driving,
          bungee jumping, winter sports involving snow or ice, or racing other
          than on foot or bicycle.
        </p>

        <p>
          <strong>General Exclusions</strong>
        </p>
        <ul>
          <li>
            Driving while the concentration of alcohol in your blood exceeds the
            legal limit or
            whilst the Life Assured is under the influence of drugs and/or medication having a
            narcotic effect, unless prescribed by a registered medical practitioner and used as
            prescribed.
          </li>
          <li>
            The Life Assured committing any breach of criminal law.
          </li>

          <li>
            Participation in any terrorist activity, war, invasion, hostilities or warlike operations,
            civil war, revolution, rebellion, insurrection, military or unsurped power, martial law,
            strike, riot or civil commotion
          </li>
          <li>
            Atomic, biological or chemical weapons as well as radioactive, biological or chemical
            substances.
          </li>
          <li>
            Attacks or intentional disruptions of operating sites or other fixed installations,
            premises or mobile equipment leading to release of radioactivity or atomic, biological
            or chemical warefare agents
          </li>
          <li>
            Sudden release of nuclear energy, nuclear radiation or radioactive
            contamination.
          </li>
        </ul>
        <br />
        <br />

        <h5>Waiting Periods</h5>

        <ol type="a">
          <li>
            From the Commencement of Insurance, there is a three (3) month
            waiting period for claims due to death by Natural Causes, for all Life Assureds under the Policy.
          </li>
          
          <li>
            There is no waiting period in respect of any Accidental Death Claim.
          </li>
          <li>
            In the event of a Life Assured increasing the Sum Assured for any
            person insured under the Policy, from the date of such increase in cover, a three (3) month waiting period will apply only to
            the amount by which the benefit increased.
          </li>
          
          <li>
            Where any premium payment is missed and thereafter paid, the part of
            the waiting period not completed at the point when the premium was not paid, will apply from the date the premium is paid.
          </li>
          
          <li>
            Where a Policy is reinstated, a new waiting period will start from
            the re-instatement date of cover.
          </li>
        </ol>

        <br />
        <br />
        <h5>Payment of benefits</h5>

        <ol type="a">
          <li>
            In terms of any accidental death claim the full sum assured will be
            paid within 2 working days upon receipt of all claim documentation supporting a valid claim
            provided the policy is in force.
          </li>
          <li>
            In terms of any claim for death due to Natural Causes the following
            levels of benefits will apply from the Commencement of Insurance,
            and any reinstatement and any increase in Sum Assured;
            <ul>
              <li>First 3 months: - 0% of the Sum Assured (waiting period) </li>
              <li>3 months to 6 months: - 50% of the Sum Assured</li>
              <li>6 months to 12 months: - 80% of the Sum Assured</li>
              <li>12 months onwards: - 100% of the Sum Assured</li>
            </ul>
          </li>
        </ol>

        <br />
        <p>
          <strong>
            <a target="_" href="/terms.pdf">Click here</a> for the full list of T&C's.
          </strong>
        </p>

        <br />
        <br />
        {
          this.props.quoteStore.selectedQuote &&
          <div>
            <h5>Illustrative Future Premiums</h5>

            <Table bordered>
              <thead>
                <tr>
                  <td>Year</td>
                  <td>Premium</td>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.quoteStore.selectedQuote &&
                  this.props.quoteStore.selectedQuote.module.age_escalation_premium.map(row => (
                    <tr>
                      <td>{row.year}</td>
                      {
                        this.props.quoteStore.selectedQuote.billing_frequency === 'monthly'
                          ? <td>{zarString(row.total_premium_monthly)}</td>
                          : <td>{zarString(row.total_premium_yearly)}</td>
                      }
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
        }

        <p>
          <strong>
            By clicking continue I confirm that I have read and agree to the terms
            stated above.
          </strong>
        </p>
        <br />
        <Button
          color="primary"
          className="px-5"
          onClick={this.props.nextStep}
          disabled={this.props.quoteStore.selectedQuoteIndex === -1}
        >
          Continue
        </Button>
        <Button color="link" onClick={this.props.previousStep}>
          Back
        </Button>
        <br />
        <br />
        <Button
          color="primary"
          className="px-5"
          onClick={() => this.props.policyholderStore.toggleCallMeModal()}
        >
          Call me
        </Button>
      </div>
    );
  }
}

export default QuoteSummary;
