export const banks = [
  { value: "absa", label: "Absa" },
  { value: "fnb", label: "FNB" },
  { value: "standard_bank", label: "Standard Bank" },
  { value: "capitec", label: "Capitec" },
  { value: "investec", label: "Investec" },
  { value: "nedbank", label: "Nedbank" },
  { value: "postbank", label: "Postbank" }
];

export const allowedRelationships = [
  { value: "spouse", label: 'Spouse' },
  { value: "parent", label: 'Parent' },
  { value: "son", label: 'Son' },
  { value: "daughter", label: 'Daughter' },
  { value: "aunt_or_uncle", label: 'Aunt or uncly' },
  { value: "grandparent", label: 'Grandparent' },
  { value: "cousin_or_relative", label: 'Cousin or relative' },
  { value: "brother", label: 'Brother' },
  { value: "sister", label: 'Sister' },
  { value: "son_in_law", label: 'Son in law' },
  { value: "daughter_in_law", label: 'Daughter in law' },
  { value: "mother_in_law", label: 'Mother in law' },
  { value: "father_in_law", label: 'father in law' },
  { value: "other", label: 'Other' }
];
