import { observable } from 'mobx';
import {
  validateName,
  validateEmail,
  validateCellphone,
  validateIdNumber,
  validateDateOfBirth,
  validateAgeAgainstIDNumber
} from '../helpers/validation';
import { utc as moment } from 'moment';
import { quoteStore } from './quote-store';

export class PolicyholderStore {
  @observable policyholderInput: { [k: string]: string } = {
    id_type: 'id'
    // TODO remove init
  };
  @observable validationResult: { [k: string]: string } = {};
  @observable createdPolicyholder?: any;

  @observable callMeModalOpen: boolean = false;
  @observable callMeModalSuccess: boolean = false;
  @observable callMeModalError: boolean = false;

  validate = () => {
    const {
      first_name,
      last_name,
      email,
      cellphone,
      date_of_birth,
      id_number,
      id_type,
      id_country
    } = this.policyholderInput;

    this.validationResult.first_name = !validateName(first_name)
      ? 'Enter a valid name.'
      : '';
    this.validationResult.last_name = !validateName(last_name)
      ? 'Enter a valid name.'
      : '';
    this.validationResult.email = !validateEmail(email)
      ? 'Enter a valid email.'
      : '';
    this.validationResult.cellphone = !validateCellphone(cellphone)
      ? 'Enter a valid cellphone number.'
      : '';

    this.validationResult.id_number =
      id_type === 'id'
        ? !validateIdNumber(id_number)
          ? 'Enter a valid SA identification number.'
          : validateAgeAgainstIDNumber(quoteStore.quoteInput.age || 0, id_number)
        : !id_number
        ? 'Enter a valid passport number.'
        : '';

    if (id_type === 'passport') {
      this.validationResult.date_of_birth = !validateDateOfBirth(
        date_of_birth,
        17,
        59
      )
        ? 'Age can range between 17 and 59'
        : '';
      this.validationResult.id_country =
        id_country !== 'ZA'
          ? 'This product is only eligible to RSA citizens residing in the republic and in possession of a valid RSA ID number.'
          : '';
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getPolicyholderApiInput = () => {
    const {
      first_name,
      last_name,
      email,
      cellphone,
      date_of_birth,
      id_number,
      id_type
    } = this.policyholderInput;

    return {
      type: 'individual',
      first_name,
      last_name,
      email,
      cellphone,
      ...(id_type === 'passport'
        ? { date_of_birth: moment(date_of_birth).format('YYYYMMDD') }
        : {}),
      id: {
        type: id_type,
        country: 'ZA',
        number: id_number
      }
    };
  };

  toggleCallMeModal = () => {
    this.callMeModalOpen = !this.callMeModalOpen;
    this.callMeModalError = false;
    this.callMeModalSuccess = false;
  };

  validateCallMeRequest = () => {
    this.validationResult = {};
    this.validationResult.first_name = !validateName(
      this.policyholderInput.first_name
    )
      ? 'Enter a valid name.'
      : '';
    this.validationResult.email = !validateEmail(this.policyholderInput.email)
      ? 'Enter a valid email.'
      : '';

    this.validationResult.cellphone = !validateCellphone(
      this.policyholderInput.cellphone
    )
      ? 'Enter a valid cellphone number.'
      : '';
  };

  requestCall = async () => {
    this.validateCallMeRequest();
    if (this.hasValidationError()) return;

    const data = {
      email: this.policyholderInput.email,
      cellphone: this.policyholderInput.cellphone,
      first_name: this.policyholderInput.first_name
    };
    try {
      const response = await fetch('/call-request', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        this.callMeModalSuccess = true;
      } else {
        this.callMeModalError = true;
      }
    } catch (error) {
      this.callMeModalError = true;
      console.error(error);
    }
  };
}

export const policyholderStore = new PolicyholderStore();
(window as any).policyholderStore = policyholderStore;
