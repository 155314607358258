import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { CreateQuote } from './create-quote';
import QuoteSummary from './quote-summary';
import CreatePolicyholder from './create-policyholder';
import PaymentDetails from './payment-details';
import CheckoutSummary from './checkout-summary';
import PolicyIssued from './policy-issued';
import { Provider } from 'mobx-react';
import { quoteStore } from '../../stores/quote-store';
import { policyholderStore } from '../../stores/policyholder-store';
import { paymentDetailsStore } from '../../stores/payment-details-store';
import { policyStore } from '../../stores/policy-store';
import { applicationStore } from '../../stores/application-store';
import { beneficiariesStore } from '../../stores/beneficiaries-store';
import { CreateApplication } from './create-application';
import { ReviewInfo } from './review-info';
import { ContactMeModal } from '../modals/contact-me';
import { CreateBeneficiaries } from './create-beneficiaries';

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: props.step
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ step: nextProps.step });
  }

  get percentageDone() {
    const result = Math.floor((this.props.step / (this.props.steps.length - 1)) * 100) 
    console.log(result)
    return result > 0 ? result : 0
  }
  render() {
    return (
      <div className="d-flex justify-content-around">
        {this.props.steps.map((s, i) => (
          <div
            className={
              'progress-step-container' +
              (this.props.step > i ? ' done' : '') +
              (this.props.step === i ? ' active' : '')
            }
            key={i}
          >
            <div className="progress-step">
              <div className="progress-dot" />
              <div className="progress-line" />
            </div>
            <div className="progress-name">{s}</div>
            {this.props.step === i && <div>{this.percentageDone}% done</div> }
          </div>
        ))}

      </div>
    );
  }
}

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      data: {
        // Policy number
        policyNumber: undefined,

        // Policholder
        policyholder: {
          type: 'policyholder',
          coverAmount: undefined,
          dateOfBirth: undefined,
          gender: undefined,
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          cellphone: undefined,
          id: {
            type: 'id',
            country: 'ZA',
            number: undefined
          }
        },

        additionalMembers: [],

        // Beneficiaries
        beneficiary: {
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          cellphone: undefined,
          id: {
            type: 'id',
            country: 'ZA',
            number: undefined
          },
          paymentDetails: {
            type: 'eft',
            bankName: undefined,
            branchCode: undefined,
            accountHolder: undefined,
            accountNumber: undefined
          }
        },

        // Payment method
        paymentMethod: {
          type: 'debit_order',
          bankDetails: {
            accountHolder: undefined,
            bankName: undefined,
            branchCode: undefined,
            accountNumber: undefined
          }
        }
      }
    };
  }
  setData(data) {
    this.setState({ data });
  }
  previousStep() {
    this.setState({ step: this.state.step - 1 });
  }
  nextStep() {
    this.setState({ step: this.state.step + 1 });
  }
  renderStep() {
    switch (this.state.step) {
      case 0:
        return (
          <CreateQuote
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 1:
        return (
          <QuoteSummary
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 2:
        return (
          <CreatePolicyholder
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 3:
        return (
          <CreateApplication
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 4:
        return (
          <ReviewInfo
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 5:
        return (
          <PaymentDetails
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 6:
        return (
          <CreateBeneficiaries
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 7:
        return (
          <CheckoutSummary
            setData={this.setData.bind(this)}
            data={this.state.data}
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 8:
        return (
          <PolicyIssued
            setData={this.setData.bind(this)}
            data={this.state.data}
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      default:
        return <div>No step found.</div>;
    }
  }
  render() {
    return (
      <Provider
        quoteStore={quoteStore}
        policyholderStore={policyholderStore}
        paymentDetailsStore={paymentDetailsStore}
        policyStore={policyStore}
        applicationStore={applicationStore}
        beneficiariesStore={beneficiariesStore}
      >
        <div>
          <nav>
            <a href="/">
              <img src="logo.png" alt="logo" height="30px" />
            </a>
          </nav>
          <Container className="pt-5">
            <ProgressBar
              step={this.state.step}
              steps={[
                'Get quote',
                'Review quote',
                'Complete personal details',
                'Complete additional details',
                'Review some important information',
                'Payment details',
                'Confirm policy'
              ]}
            />
            <br />
            <br />
            <div className="page-container">{this.renderStep()}</div>
            <ContactMeModal />
          </Container>
          <div id="footer"style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
            <img alt='logo-omart' src="OMART_footer_logo_horizontal.png" height="40px"/>
          </div>
        </div>
      </Provider>
    );
  }
}

export default Application;
