/* global fetch:false Request:false */
const key =
  process.env.REACT_APP_API_KEY ||
  'sandbox_Nzc4NmE3NGItMzc1YS00NDAwLTg1YTEtODhmMTQwNTRlYjQ4LmhudGVXVjlsV1ZIVEpaMDlTV1BxSndSMWJLcUlsUzhF';
const auth = 'Bearer ' + key;
const sandbox = process.env.REACT_APP_SANDBOX;
const basePath = `https://api.root.co.za`;

const makeRequest = (method, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: data && JSON.stringify(data),
  };

  const request = new Request(basePath + path);

  return fetch(request, options).then(response => {
    return response.json().then(r => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

export default {
  getQuote: (type, data) => makeRequest('POST', { type, ...data }, '/v1/insurance/quotes'),
  createPolicyholder: data => makeRequest('POST', data, '/v1/insurance/policyholders'),
  updatePolicyholder: data => makeRequest('PUT', data, `/v1/insurance/policyholders`),
  createApplication: data => makeRequest('POST', data, '/v1/insurance/applications'),
  createPolicy: data => makeRequest('POST', data, '/v1/insurance/policies'),
  addPaymentMethod: (policyholderId, data) =>
    makeRequest('POST', data, `/v1/insurance/policyholders/${policyholderId}/payment-methods`),
  createAttachment: (policyId, data) => makeRequest('POST', data, `/v1/insurance/policies/${policyId}/attachments`),
  createBeneficiaries: (policyId, data) => makeRequest('PUT', data, `/v1/insurance/policies/${policyId}/beneficiaries`),
  callRequest: data => makeRequest('POST', data, '/call-request'),
};
