import * as React from 'react';
import Slider from '@material-ui/core/Slider';
import { FormGroup, FormFeedback, Label } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const PrettoSlider = withStyles({
  root: {
    color: '#99c929',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  markLabel: {
    color: 'initial',
    '&:focus,&:hover,&$active':{
      color: 'initial',
    }
  }
})(Slider);

interface Props {
  id: string;
  label: string;
  value: number;
  onChange: (e: any) => void;
  invalid: boolean;
  validationMessage: string;
  min: number;
  max: number;
  step: number;
  marks: {value: number, label: React.ReactNode}[]
  rightLabel: (value: number) => React.ReactNode
}

export const SliderInput: React.SFC<Props> = observer((props: Props) => {
  return (
    <FormGroup>
      <Label for={props.id} style={{width:'100%', display: 'flex', justifyContent: 'space-between', margin: 0}}>
        <span>
          {props.label}
        </span>
        {
          props.rightLabel(props.value)
        }
      </Label>
      {/* <PrettoSlider
        min={props.min}
        max={props.max}
        step={props.step}
        value={props.value}
        onChange={(event, value) => props.onChange(value)}
        valueLabelDisplay={'off'}
        marks={props.marks}
      /> */}
      <FormFeedback style={{ display: !!props.invalid && 'block' }} >
        {props.validationMessage}
      </FormFeedback>
    </FormGroup>
  );
})
