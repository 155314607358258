import * as React from 'react';
import { Button } from 'reactstrap';
import { PolicyholderStore } from '../../stores/policyholder-store';
import { inject, observer } from 'mobx-react';

interface Props {
  nextStep: () => void;
  previousStep: () => void;
  policyholderStore?: PolicyholderStore;
}

export const ReviewInfo: React.SFC<Props> = inject('policyholderStore')(
  observer(props => {
    if (!props.policyholderStore) return null;

    return (
      <div>
        <h1>Important information</h1>
        <br />
        <h5>You’re nearly there! Before you check out, here’s some important stuff to note.</h5>
        (It’s important that you also read the full T&Cs, which you can access below, and which we’ll send you once
        you’ve completed the sign-up process.)
        <br />
        <br />
        <h5>Insurer</h5>
        This policy is underwritten by Old Mutual Alternative Risk Transfer Limited <br />
        (“OMART”) a Licensed Life Insurer. <br />
        Postal address: PO Box 66, Cape Town, 8000 <br />
        Telephone; 021 504 6423 <br />
        <br />
        <h5>Replacement Polices</h5>
        If this policy is to replace any other policy with another assurer, then kindly note in this event you may
        contact your Financial Advisor who will assist you with a detailed comparison.
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for the full list of T&C's.
        <br />
        <br />
        <h5>Financial Advice</h5>
        You have chosen this death benefit yourself and have not received any advice from Shackleton Life regarding the
        application for this policy. Shackleton Life does not provide financial advice as defined by the Financial
        Advisory and Intermediary Services Act (FAIS).Should you require financial advice, Financial Needs Analysis or a
        detailed comparison between products you may consult a qualified Financial Planner who will assist you in this
        regard.
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for the full list of T&C's.
        <br />
        <br />
        <h5>Remuneration</h5>
        Shackleton Life (Pty) Limited earns a commission and binder fee based on the total monthly/annual premium.
        <br />
        <br />
        <h5>Personal Information</h5>
        Shackleton Life takes the protection of your personal information very seriously. By accepting the terms and
        conditions, you consent to the sharing of your information knowing that this will be kept confidential. We
        respect your privacy and take great care to protect the confidential information you give us; we will deal with
        it in a lawful manner. Shackleton Life will not share your personal information with any third party except if
        it is required to do so in the ordinary course of business, or where required by law. We don’t like spam and we
        won’t sell your details to anyone for their marketing purposes. Shackleton Life may collect and process certain
        personal information for communication and administrative purposes. Collection and processing of this
        information will help us give you better service and create products tailored to meet your needs.
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for further details regarding OMART and Shackleton Life’s respective POPI Disclosure and relevant marketing
        opt-out procedures, which appear on the full list of T&C’s.
        <br />
        <br />
        <h5>Cooling Off Period</h5>
        The Life Assured has 31 days from receipt of the Policy to decide to cancel the policy with no penalties,
        provided that no death or claims have taken place during this period. All premiums already paid will be
        refunded.
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for the full list of T&C's.
        <br />
        <br />
        <h5>Term (Whole of Life)</h5>
        The policy is a whole of life policy, which means that there is no term associated with the policy and that it
        can be kept for your whole life as long as you continue to pay the monthly premiums.
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for the full list of T&C's.
        <br />
        <br />
        <h5>Grace Period</h5>A period of one calendar month calculated from the premium payment date chosen by the Life
        Assured in the Policy Schedule, which will be allowed for the payment of each premium. If the premium is not
        paid within the Grace Period the policy will lapse. The Grace Period does not apply to the payment of the first
        premium. If the first premium is not paid as required, the Policy will lapse.
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for the full list of T&C's.
        <br />
        <br />
        <h5>Nominate a Beneficiary</h5>
        This is the person who will be responsible for paying for your family’s expenses in the event of your death. The
        sum you are assured for will be paid directly to their bank account.
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for the full list of T&C's.
        <br />
        <br />
        <h5>Reinstatement</h5>
        Should the Policy lapse it may be reinstated within six (6) months of such lapse, subject to the recommencement
        of all Waiting Periods and Exclusions in terms of the Policy Wording and any such terms and conditions as OMART
        may impose at the time of reinstatement.
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for the full list of T&C's.
        <br />
        <br />
        <h5>Absence from the Republic of South Africa</h5>
        <ol type='a'>
          <li>
            If you’re planning on leaving the Republic of South Africa for more than 90 consecutive days for non-holiday
            purposes please notify us before your leaving. If OMART is not informed, OMART may decline the claim within
            the period of your absence and the cover may cease.
          </li>
          <li>
            OMART reserves the right to cancel the Policy if not notified of the Life Assured leaving the Republic of
            South Africa.
          </li>
        </ol>
        <br />
        <h5>Complaints</h5>
        Should you be dissatisfied about any matter relating to the policy or to any service provided or offered by
        OMART or Shackleton Life you may contact the appropriate Complaints Department as follows:
        <br />
        <br />
        <h5>OMART</h5>
        Compliance Officer: 021 509 3113 <br />
        Complaints: omartcomplaints@oldmutual.com <br />
        <br />
        <h5>Shackleton Life (Pty) Ltd: Policy Administration and Claims</h5>
        PO Box 2507, Mount Edgecombe, 4301 <br />
        Telephone: 087 232 7014 <br />
        Compliance Department email compliance@shackletonlife.co.za
        <br />
        <br />
        <a target='_' href='/terms.pdf'>
          Click here
        </a>{' '}
        for the full list of T&C's.
        <br />
        <br />
        <Button color='primary' className='px-5' onClick={() => props.nextStep()}>
          Continue
        </Button>
        <Button color='link' onClick={() => props.previousStep()}>
          Back
        </Button>
        <br />
        <br />
        <Button color='primary' className='px-5' onClick={() => props.policyholderStore.toggleCallMeModal()}>
          Call me
        </Button>
      </div>
    );
  }),
);
